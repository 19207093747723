import { DEFAULT_LANGUAGE } from './features-flag';
/**
 * ************************************************
 * Time & Language Property
 * ************************************************
 */
import dayjs from 'dayjs';
import 'dayjs/locale/id';
import 'dayjs/locale/en';

/**
 * ************************************************
 * Bride & Groom Info
 * ************************************************
 */
export const IS_BOY_FIRST = true; //change to false for switch broadcast text

export const GIRL_NAME = 'Ketut Chandra Ratnasari, S.Tr.Log';
export const GIRL_NAME_SHORT = 'Chandra ';
export const GIRL_FULL_NAME = GIRL_NAME;
export const GIRL_FATHER_NAME = `Putu Widhiasa`;
export const GIRL_MOTHER_NAME = `Anak Agung Ayu Yasmini (Alm)`;
export const GIRL_PARENT_NAME = 
  DEFAULT_LANGUAGE === 'en'
    ? `The daughter of Mr. ${GIRL_FATHER_NAME} <br />and Mrs. ${GIRL_MOTHER_NAME}`
    : `Putri dari <br />Bapak ${GIRL_FATHER_NAME} <br />dan Ibu ${GIRL_MOTHER_NAME}`
  ;

// --- boy sections
export const BOY_NAME = 'dr. I Made Surya Budikusuma, S.Ked';
export const BOY_NAME_SHORT = 'Surya';
export const BOY_FULL_NAME = BOY_NAME;
export const BOY_FATHER_NAME = `Dr. dr. I Gede Budiarta, Sp.An-TI, Subsp.M.N.(K)`;
export const BOY_MOTHER_NAME = `Ida Ayu Dewi Mahanarayani, S.E`;
export const BOY_PARENT_NAME =
  DEFAULT_LANGUAGE === 'en'
    ? `The son of Mr. ${BOY_FATHER_NAME} <br />and Mrs. ${BOY_MOTHER_NAME}`
    : `Putra dari <br />Bapak ${BOY_FATHER_NAME} <br />dan Ibu ${BOY_MOTHER_NAME}`
  ;

export const THE_BRIDE = IS_BOY_FIRST
  ? `${BOY_NAME_SHORT} & ${GIRL_NAME_SHORT}` 
  : `${GIRL_NAME_SHORT} & ${BOY_NAME_SHORT}`;

/**
 * ************************************************
 * Instagram Profile Account
 * @important please put instagram id without `@`
 * ************************************************
 */
export const IG_BOY = 'amusukidub';
export const IG_GIRL = 'chandratnasarii';

/**
 * ************************************************
 * SEO Requirement
 * @important - Don't forget to update SEO IMAGE
 * ************************************************
 */
export const SEO_IMAGE = `https://ik.imagekit.io/zaanfa/surya-chandra/seo_sKEItIaL1.jpg?updatedAt=1695884003599`;
export const SEO_URL = 'https://invitato.net/';
export const SEO_TITLE = `The Wedding of ${THE_BRIDE} by Invitato`;
export const SEO_DESCRIPTION =
  DEFAULT_LANGUAGE === 'en'
    ? `Together with joyful hearts, we are pleased to announce the beginning of this new chapter of our lives together. Please click the Website Invitation link on this message for more information about our wedding details:`
    : `Menjadi sebuah kebahagiaan bagi kami untuk mengumumkan awal dari babak baru kehidupan kami bersama. Silakan klik tautan situs Undangan Website di bawah untuk informasi lebih lanjut:`;

/**
 * ************************************************
 * Time requirement for Counting Down, and
 * Remind me generator
 * @important - please convert time to Epoch time by
 * using this link https://www.epochconverter.com/
 * ************************************************
 */
export const EPOCH_START_EVENT = 1697788800;
export const EPOCH_RESEPSI_START = 1697788800;
export const EPOCH_END_EVENT = 1697796000;

/**
 * ************************************************
 * DATE REQUIREMENT
 * @important - Format date YYYY-MM-DD
 * ************************************************
 */
const DATE_AKAD = new Date('2023-10-20');
const DATE_RESEPSI = new Date('2023-10-20');

export const WEDDING_AKAD_DATE = dayjs(DATE_AKAD).locale(DEFAULT_LANGUAGE).format('DD MMMM YYYY');
export const WEDDING_AKAD_TIME = '16.00 WITA';
export const WEDDING_AKAD_LOC_NAME = 'Kediaman Mempelai';
export const WEDDING_AKAD_LOC_ROAD = 'Jl. Gunung Karang No. 11C';
export const WEDDING_AKAD_FULLDATE = dayjs(DATE_AKAD)
  .locale(DEFAULT_LANGUAGE)
  .format('dddd, DD MMMM YYYY');

export const WEDDING_RESEPSI_FULLDATE = dayjs(DATE_AKAD)
  .locale(DEFAULT_LANGUAGE)
  .format('dddd, DD MMMM YYYY');
export const WEDDING_RESEPSI_TIME = '16.00 WITA';
export const WEDDING_RESEPSI_LOC_NAME = 'Kediaman Mempelai';
export const WEDDING_RESEPSI_LOC_ROAD = `Jl. Gunung Karang No. 11C`;
export const WEDDING_DATE = dayjs(DATE_RESEPSI).format('DD.MM.YYYY');

/**
 * ********************************************************
 * Link Generator V2
 * @important - this info will be applied at link generator
 * ********************************************************
 */
export const HOSTNAME = 'https://suryadanchandra.com/';
export const BROADCAST_WEDDING_LOCATION = `Kediaman Mempelai, Jl. Gunung Karang No. 11C`;
export const BROADCAST_WEDDING_DAY = {
  id: dayjs(DATE_RESEPSI).locale('id').format('dddd, DD MMMM YYYY'),
  en: dayjs(DATE_RESEPSI).locale('en').format('dddd, DD MMMM YYYY'),
};

/**
 * ************************************************
 * Maps Location
 * ************************************************
 */
export const GOOGLE_MAPS_LINK = `https://maps.app.goo.gl/faKyLYo5ARryqCdT6`;
export const GOOGLE_MAPS_ADDRESS = `Jl. Gunung Karang No. 11C`;

/**
 * ************************************************
 * Backsound Copyright
 * ************************************************
 */
export const SOUND_BY = `eleventwelfth feat. asteriska - your head as my favourite bookstore`;
export const SOUND_URL = 'https://youtu.be/srxrsXwJQ_Q?si=GZJ4b5w2ydLta8mo';

/**
 * ************************************************
 *  Youtube Live requirement
 * ************************************************
 */
export const YOUTUBE_EMBED = 'https://www.youtube.com/embed/6nExoZI7rHk?si=mpPis3_6ZD9Z6VSb';
export const YOUTUBE_LINK = 'https://youtu.be/6nExoZI7rHk?si=mpPis3_6ZD9Z6VSb';
export const DISABLE_IFRAME = false;

/**
 * ************************************************
 *  RSVP & Wishlish Section
 * ************************************************
 */
export const API_HOSTNAME_RSVP = `https://script.google.com/macros/s/AKfycbywNJhkrH5i1t71PfjsP560YlDlvLOLxtktaxEtOotOkO6YfDfCDp1UpsfosgOr39Xh/exec`;
export const API_RSVP_TABLE = 'rsvp';
export const API_HOSTNAME = API_HOSTNAME_RSVP;
export const API_TABLE_NAME = 'wishlist';
export const SHEET_GIFT_CONFIRMATION = 'gift_confirmation';

/**
 * ************************************************
 *  Invitato Link Requirement
 * ************************************************
 */
export const URL_INVITATO = 'https://invitato.net';
export const URL_IG_INVITATO = 'https://www.instagram.com/invitato.id/';
export const URL_WA_INVITATO = `https://wa.me/+6281329274582?text=Hi%20Kak,%20aku%20mau%20pesan%20undangan%20online%20nih!`;